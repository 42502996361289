<template>
  <div>
    <LiefengModal
        title="修改功能"
        :value="controlForm.formFlag"
        :fullscreen="false"
        :width="600"
        :height="600"
        @input="closeModal"
    >
      <template v-slot:contentarea>
        <Form
            :model="formData"
            :label-colon="true"
            :label-width="100"
            :rules="formDataValidate"
            ref="form"
        >

          <FormItem label="功能名称" prop="functionName">
            <Input style="width:300px" v-model.trim="formData.functionName" :maxlength="50"
                   placeholder="请填写功能名称"></Input>
          </FormItem>
          <FormItem label="功能编码" prop="functionCode">
            <Input style="width:300px" v-model.trim="formData.functionCode" :maxlength="50"
                   placeholder="请填写功能编码"></Input>
          </FormItem>
          <FormItem label="PC端路径" prop="urlPc">
            <Input style="width:300px" v-model.trim="formData.urlPc" :maxlength="50"
                   placeholder="请填写PC端路径"></Input>
          </FormItem>
          <FormItem label="H5路径" prop="urlH5">
            <Input style="width:300px" v-model.trim="formData.urlH5" :maxlength="50"
                   placeholder="请填写H5路径"></Input>
          </FormItem>
          <FormItem label="微信路径" prop="urlWechat">
            <Input style="width:300px" v-model.trim="formData.urlWechat" :maxlength="50"
                   placeholder="请填写微信路径"></Input>
          </FormItem>
          <FormItem label="配置参数JSON">
            <Input type="textarea" rows="5" v-model.trim="formData.configJson" style="width:300px" placeholder="请填写配置参数JSON"></Input>
          </FormItem>

        </Form>
      </template>
      <template v-slot:toolsbar>
        <Button type="info" style="margin-right:10px" @click="closeModal(false)">取消</Button>
        <Button type="primary" @click="saveData">确定</Button>
      </template>
    </LiefengModal>
  </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";

export default {
  components: {LiefengModal},
  data() {
    return {
      controlForm: {
        formFlag: false,
        timer:'',
        fatherFunctionList: '',
      },
      formData: {
        functionId: '',
        parentId: '',
        functionName: '',
        functionCode: '',
        urlPc: '',
        urlH5: '',
        urlWechat: '',
        configJson: '',
      },
      formDataValidate: {
        parentId: [
          {required: true, message: '父功能不能为空', trigger: 'change'}
        ],
        functionName: [
          {required: true, message: '功能名称不能为空', trigger: 'blur'}
        ],
        functionCode: [
          {required: true, message: '功能编码不能为空', trigger: 'blur'}
        ],
      },
    }
  },
  methods: {
    changeEndTime(time){
      this.formData.endTime = time;
    },
    saveData() {
      this.$refs.form.validate((valid)=> {
            //校验json
            var configJson = this.formData.configJson
            if (configJson && typeof configJson == 'string') {
              try {
                  var obj = JSON.parse(configJson);
                  if (typeof obj != 'object' || !obj) {
                    this.$Message["error"]({
                      background: true,
                      content: "JSON格式不正确！"
                    });
                    return
                  }
              } catch (e) {
                this.$Message["error"]({
                  background: true,
                  content: "JSON格式不正确！"
                });
                  return
              }
            }

            if (valid) {
              this.$post('/auth/api/auth/pc/systemFunction/update',{
                ...this.formData,
              }).then(res=>{
                if(res.code == 200){
                  //刷新父功能
                  this.$emit('getFatherFunctionList');
                  this.$Message.success({
                    content:'更新成功',
                    background:true
                  });
                  //清空表单
                  Object.assign(this.$data.formData, this.$options.data().formData);
                  //重置表单字段Form
                  this.$refs['form'].resetFields();
                  //父页面刷新列表
                  this.$emit('resetBtn')
                  this.$emit('getAllFunction')
                  this.closeModal();
                }else{
                  this.$Message.error({
                    content:res.desc,
                    background:true
                  })
                  return
                }
              })
            }
          }
      );
    },
    closeModal(flag){
      //关闭弹窗
      this.controlForm.formFlag = flag;
      // //清空表单
      Object.assign(this.$data.formData, this.$options.data().formData);
      // //重置表单字段Form
      this.$refs['form'].resetFields();
      // //父页面刷新列表
      // this.$emit('resetBtn')
    },
    getSystemDetail(functionId) {
      this.$get('/auth/api/auth/pc/systemFunction/queryByFunctionId',{
        functionId
      }).then(res =>{
        if(res.code == 200){
          this.formData  = res.data
          delete this.formData.gmtCreate
          delete this.formData.enableStatus
          delete this.formData.children
        }else{
          this.$Message.error({
            content:'获取功能详情失败',
            background:true
          })
          return
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
/deep/ .validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/ #modal_contentarea{
  overflow: inherit !important;
}
</style>